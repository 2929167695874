import React, { useEffect } from "react"
import { Select, MenuItem, TextField} from "@mui/material"
import MuiStyles from "./MuiStyles"
import { useState } from "react"

const DatiLinea = ({ funcs, States }) => {
    const setValue = funcs.setValue
    const Shrink = funcs.Shrink
    const Text_Content = States.Text_Content
    const [Linea, setLinea] = useState("0")
    useEffect(() => {
        setValue({ target: { value: "0" } }, "Servizio")
        setValue({ target: { value: "FTTH" } }, "Piano")
    },[])
    useEffect(() => {
        setLinea(Text_Content["Servizio"] || "00")
        for (let i = 1; i < 4; i++) {
            if ((Text_Content["Linee" + i] !== undefined) && (Linea !== "4" && Linea !== "5"))
                Text_Content["Linee" + i] = ""
            if ((Text_Content["Codice_Migr" + i] !== undefined) && (Linea !== "4" && Linea !== "3"))
                Text_Content["Codice_Migr" + i] = ""
        }
    },[Text_Content, setLinea, Linea])
    return (
        <form style={{ display: 'flex', flexDirection: 'column', rowGap: '0px', justifyContent: 'center', alignItems: 'center' }} className="Form">

            <h3>Dati Linea</h3>
            <div style={{ rowGap: '0px', width: '95%', padding: '20px' }} className="Panel">
                <div id="Intern"  >
                    <div style={{ textAlign: 'left', width: '100%', marginTop: '5px' }}>
                        <span style={{ fontWeight: 'bold', textAlign: 'left', marginLeft: '5px' }}>Piano Velocità</span>
                    </div>
                    <div style={{ marginLeft: '5px' }}>
                        <span style={{ fontSize: '1rem', margin: '0px', lineHeight: '1.66', letterSpacing: '0.0333em' }}>
                            Seleziona un piano di velocità per la tua connessione internet:
                        </span>
                    </div>
                    <div style={{ margin: '30px', width: '80%' }}>
                        <Select sx={{ width: '70%', ...MuiStyles.Select }} 
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        ...MuiStyles.MenuItem
                                    }
                                }
                            }}
                            id="Piano"
                            name="Piano"
                            onChange={(e) => setValue(e, "Piano")}
                            defaultValue={Text_Content["Piano"] || "FTTH"}>
                            <MenuItem value="FTTH">FTTH</MenuItem>
                            <MenuItem value="FTTC">FTTC/VDSL</MenuItem>
                            <MenuItem value="VOCE">VOCE</MenuItem>
                        </Select>
                    </div>
                    <ul >
                        <li style={{ width: '95%' }} className="SimplePar" ><span className="Marked">FTTH :</span> Rete Completamente in Fibra ottica, garantisce un'altà velocità e affidablità.</li>
                        <li style={{ width: '95%' }} className="SimplePar" ><span className="Marked">FTTC/VDSL :</span> Rete Misto Rame e Fibra Ottica. Arriva ad un armadio in Fibra ed è smistato poi in rame</li>
                        <li style={{ width: '95%' }} className="SimplePar" ><span className="Marked">VOCE :</span> Se si sceglie quest'opzione si vuole attivare unicamente una linea telefonica.</li>
                    </ul>
                    <div style={{ marginLeft: '5px' }}>
                        <span style={{ fontSize: '1rem', margin: '0px', lineHeight: '1.66', letterSpacing: '0.0333em' }}>
                            Come vuoi attivare il servizio? Desideri:
                            <ul>
                                <li><span className="Marked">Attivare un nuovo servizio</span></li>
                                <li style={{ width: '95%' }} className="SimplePar"><span className="Marked">Migrare un servizio esistente :</span> Passaggio della linea Internet da un operatore all'altro attraverso un codice (codice migrazione) individuabile sulla fattura (o richiesto direttamente al proprio operatore)</li>
                            </ul>
                        </span>
                    </div>
                    <p className="SimplePar">Selezionare quindi il servizio:</p>
                    <div  className="basicFlexColumn" style={{width: '95%',margin: "15px 10px 10px"}}>
                        <Select sx={{ width: '70%', ...MuiStyles.Select}} onChange={(e) => {setLinea(e.target.value); setValue(e, "Servizio")}}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        ...MuiStyles.MenuItem
                                    }
                                }
                            }}
                            id="Servizio"
                            value={Text_Content["Servizio"] || "0"}
                            >
                            <MenuItem value="0">Attivare un nuovo servizio solo Connessione</MenuItem>
                            <MenuItem value="1">Attivare un nuovo servizio solo Numero</MenuItem>
                            <MenuItem value="2">Attivare un nuovo servizio Conessione e Numero</MenuItem>
                            <MenuItem value="3">Migrare Conessione senza mantenere Numero</MenuItem>
                            <MenuItem value="4">Migrare Connessione Mantenendo Numero</MenuItem>
                            <MenuItem value="5">Migrare Solo Numero</MenuItem>
                        </Select>
                    </div>
                    {
                        (Linea === "4" || Linea === "3" || Linea === "5") &&
                        <div style={{ width: '100%' }}>
                            <span style={{ textAlign: 'left', marginLeft: '2.5%', color: 'rgba(0, 0, 0, 0.6)' }}>Codici Migrazione/Trasferimento Utenza:</span>
                            <div className="rowContainer" style={{ width: '95%', justifyContent: 'center', margin: '10px' }}>
                                <TextField fullWidth variant="outlined" sx={{ ...MuiStyles.TextField }} label="Codice1" name="Codice_Migr1" value={Text_Content["Codice_Migr1"]} onChange={setValue} InputLabelProps={{ shrink: Shrink("Codice_Migr1") }} />
                                <TextField fullWidth variant="outlined" sx={{ ...MuiStyles.TextField }} label="Codice2" name="Codice_Migr2" value={Text_Content["Codice_Migr2"]} onChange={setValue} InputLabelProps={{ shrink: Shrink("Codice_Migr2") }} />
                                <TextField fullWidth variant="outlined" sx={{ ...MuiStyles.TextField }} label="Codice3" name="Codice_Migr3" value={Text_Content["Codice_Migr3"]} onChange={setValue} InputLabelProps={{ shrink: Shrink("Codice_Migr3") }} />
                            </div>
                        </div>
                    }
                    {
                        (Linea === "4" || Linea === "5") && <>
                            <span style={{ textAlign: 'left', marginLeft: '2.5%', color: 'rgba(0, 0, 0, 0.6)' }}>Linee Telefoniche:</span>
                            <div className="rowContainer" style={{ width: '95%', justifyContent: 'center', margin: '10px' }}>
                                <TextField fullWidth variant="outlined" sx={{ ...MuiStyles.TextField }} label="Linee1" name="Linee1" value={Text_Content["Linee1"]} onChange={setValue} InputLabelProps={{ shrink: Shrink("Linee1") }} />
                                <TextField fullWidth variant="outlined" sx={{ ...MuiStyles.TextField }} label="Linea2" name="Linee2" value={Text_Content["Linee2"]} onChange={setValue} InputLabelProps={{ shrink: Shrink("Linee2") }} />
                                <TextField fullWidth variant="outlined" sx={{ ...MuiStyles.TextField }} label="Linea3" name="Linee3" value={Text_Content["Linee3"]} onChange={setValue} InputLabelProps={{ shrink: Shrink("Linee3") }} />
                            </div>
                        </>
                    }
                    {
                        Linea !== "" && <div className="basicFlexColumn" style={{ margin: '10px', width: '95%', marginTop: '15px' }}>
                            <TextField sx={{
                                    ...MuiStyles.TextField
                                }}
                                    label="Specificare l’indirizzo dove verra’ utilizzata la numerazione"
                                    name="Indirizzo_Num"
                                    InputLabelProps={{ shrink: Shrink("Indirizzo_Num") }}
                                    helperText="*In caso di mancata compilazione si intenderà dichiarato come indirizzo di utilizzo solo ed
                                        esclusivamente quello riportato nell’anagrafica alla voce “intestatario linee da attivare”"
                                    onChange={setValue}
                                    value={Text_Content["Indirizzo_Num"]}
                                />
                        </div>
                    }
                    {
                        Linea !== "" && <>
                            <div className="basicFlexColumn" style={{ width: '95%', marginTop: '15px' }}>
                                <TextField sx={{
                                    ...MuiStyles.TextField
                                }}
                                    InputLabelProps={{ shrink: Shrink("Note_Linee") }}
                                    name="Note_Linee"
                                    label="Note sulle linee da attivare/piano tariffario"
                                    helperText="* Aggiunere eventuali Info importanti(Es. presenza di un centralino, servizi aggiuntivi, tipologia linee da migrare/attivare)"
                                    onChange={setValue}
                                    value={Text_Content["Note_Linee"]}
                                />
                                
                            </div>
                        </>
                    }
                </div>
            </div>
        </form>
    )
}
export default DatiLinea