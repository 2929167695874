// @ts-nocheck
import MuiStyles from "./MuiStyles"
import React, { useEffect } from "react"
import { TextField } from "@mui/material"
import SmSToken from "./SmSToken"
import { useNavigate } from "react-router-dom";
import ReSend from "./ReSend";
import FormRequest from "./FormRequest";
import Warning from "./Warning";
import Condizioni from "./docs/TLC_CGC_02082024.pdf"
import FileSaver from "file-saver";
const SmsVerify = () =>{
    const navigate = useNavigate()
    const [base64,changeBase64] = React.useState([])
    const [Currentindex,changeCurrentIndex] = React.useState(0)
    const [error, setError] = React.useState("")
    useEffect(()=>{
        if (base64.length !== 0)
            return
        const asyncchangeBase64 = async() =>{
            let arr = []
            arr.push(await FormRequest.GetContract(localStorage.getItem("MailSession")))
            arr = arr.concat(await FormRequest.GetOffer(localStorage.getItem("MailSession")))
            changeBase64(arr)
        }
        asyncchangeBase64()     
    },[base64])
    const [Status, changeStatus] = React.useState({
        Status: false,
        Error: ""
    })
    const checkflags = () =>{
        for (let i = 1; i < 3; i++){
            // @ts-ignore
            console.log(document.getElementById("Check"+i).checked)
            // @ts-ignore
            if (!document.getElementById("Check"+i).checked)
                return false
        }
        return true
    }
    const update = async() => {
        if(!checkflags()){
            setError("Devi accettare tutti i termini e condizioni")
            return
        }
        // @ts-ignore
        if (document.getElementById("SMSToken").value === ""){
            setError("Devi inserire il token")
            return
        }
        // @ts-ignore
        const error = await SmSToken.SmsVerify(document.getElementById("SMSToken").value,localStorage.getItem("sessionID"),document.getElementById("Check3").checked)
        changeStatus({
            Status: true,
            Error: error
        })
    }
    const OnWrong = () => {
        if (Status.Error === "Token non corrispondente")
            changeStatus({
                Status: false,
                Error: ""
            })
        else{
            localStorage.removeItem("x-access-token")
            navigate("/",{replace: true})
        }
    }
    useEffect(()=>{
        if (error !== "")
            document.getElementById("Basic1").showModal()
    },[error])

    const Setindex = (index) => {
        document.getElementById("B"+index).disabled = true
        document.getElementById("B"+Currentindex).disabled = false
        changeCurrentIndex(index)

    }

    const SaveCondizioni = () => {
        FileSaver.saveAs(Condizioni,"CondizioniGeneraliContrattoTLC.pdf")
    }
    return(
        <div style={{display:'flex',alignItems: 'center',justifyContent: 'center',flexDirection: 'column'}}>
            <h1>Firma</h1>
            <Warning title={<span className="titledialog">Attenzione!</span>} id="Basic1">
                <div className="desc">
                    <span style={{fontSize: '20px', padding: '10px'}}>{error}</span>
                </div>
            </Warning>
            {(!Status.Status && <>
                <div style={{rowGap: '10px',width: '60%', padding: '20px'}} className="Panel">
                    <p style={{margin: '0'}}>Accetta le condizioni e inserisci di seguito il token che ti arriverà per sms per completare la procedura di firma. Qui puoi visualizzare i documenti che stai per firmare:</p>
                    <span className="Title" style={{fontSize: '20px'}}>Visualizza i Documenti</span>
                    <div className="rowContainer" style={{justifyContent: 'center'}}>
                        <button className="btn" style={{ width: '10vw', margin: "0 4% 0 0%" }} id="B0" onClick={() => Setindex(0)}>
                            <span className="Title" style={{ fontSize: '18px', color: 'inherit' }} >Contratto</span>
                        </button>
                        <button className="btn" style={{ width: '10vw',margin: "0 4% 0 0%" }} id="B1" onClick={()=>Setindex(1)}>
                            <span className="Title" style={{ fontSize: '18px', color: 'inherit' }} >Offerta</span>
                        </button>
                        {
                            base64.length > 2 &&
                            <button className="btn" style={{ width: '12vw',margin: "0 0% 0 0%" }} id="B2" onClick={()=>Setindex(2)}>
                                <span className="Title" style={{ fontSize: '18px', color: 'inherit' }} >Quotazione apparato</span>
                            </button>
                        }
                        
                    </div>
                    {<iframe title="Contract" style={{height: "60vh",width: '100%'}} className="form-control" src={"data:application/pdf;base64," + base64[Currentindex] + "#view=FitW&toolbar=0"} id="pdf"/>}
                    <div style={{display: "flex", flexDirection: 'column', justifyContent: 'left', alignItems: 'start', width: '100%'}}>
                        <div className="rowContainer" style={{margin: '10px',width: '80%', justifyContent:'left'}}>
                            <input type="checkbox" id = "Check1" value="Check1"  required />
                            <label style={{fontSize: '0.65rem',lineHeight: "1.66",letterSpacing: "0.03em"}}> 
                                Il Cliente dichiara di aver letto e di accettare le <span onClick={SaveCondizioni} style={{fontWeight: '600',textDecoration: 'underline', cursor: 'pointer'}}>Condizioni Generali di Contratto</span> applicate al Servizio richiesto, di aver ricevuto e di accettare la Sintesi contrattuale e le informazioni contenute nella Trasparenza Tecnica e manifesta la volontà di concludere il Contratto
                            </label>
                        </div>
                        <div className="rowContainer" style={{margin: '10px',width: '80%', justifyContent:'left'}}>
                            <input type="checkbox" id = "Check2" value="Check2" required />
                            <label style={{fontSize: '0.65rem',lineHeight: "1.66",letterSpacing: "0.03em"}}>Il Cliente dichiara di aver letto e conoscere le condizioni contrattuali ed economiche applicate al presente Contratto e, di aver letto ed approvato specificatamente, ai sensi degli articoli 1341 e 1342 c.c., le clausole di cui ai seguenti articoli delle Condizioni Generali di Contratto: 2 (Conclusione del Contratto); 3 (Attivazione del Servizio); 4 (Durata e Recesso) 5 (Modifiche unilaterali); 6 (Servizio di accesso a internet); 7 (Servizio di telefonia fissa); 8 (Centralino Cloud); 9 (Servizio di Fax Virtuale); 10 (Apparati e dispositivi); 11 (Migrazione della linea e portabilità della numerazione); 13 (Sospensione del Servizio per guasto o manutenzione); 14 (Servizio di Assistenza Clienti); 15 (Obblighi del Cliente); 16 (Limiti di responsabilità); 17 (Corrispettivo e modaità di pagamento); 18 (Traffico anomalo); 20 (Clausola risolutiva espressa); 21 (Cessione del Contratto); 24 (Foro competente e tentativo obbligatorio di conciliazione)</label>
                        </div>
                        <div className="rowContainer" style={{margin: '10px',width: '80%', justifyContent:'left'}}>
                            <input type="checkbox" id = "Check3" value="Check3" required />
                            <div className="basicFlexColumn" style={{rowGap: '0', alignItems: 'normal'}}>
                                <label style={{fontSize: '0.65rem',lineHeight: "1.66",letterSpacing: "0.03em"}}>Il Cliente dichiara di aver preso visione dell’informativa, ai sensi dell’art. 13 del Regolamento UE 679/2016 “GDPR”, all’art. 23 delle Condizioni Generali di Contratto circa il trattamento dei dati personali e in relazione e per le finalità specificatamente indicate al punto 23.3 dell’informativa ed esprime il consenso al trattamento per le finalità di cui alla lett. B) (finalità di Marketing)</label>
                                <label style={{fontSize: '0.65rem',lineHeight: "1.66",letterSpacing: "0.03em"}}>(qualora non volesse acconsentire al relativo trattamento, il Cliente può lasciare la casella non flaggata)</label>
                            </div>
                        </div>
                    </div>
                    <TextField sx={{
                        ...MuiStyles.TextField
                    }}  label="Token SMS" variant="outlined" name="Token" id="SMSToken" fullWidth/>
                    
                </div>
                <div style={{width: '45%',columnGap: '30px',margin: '20px', display:'flex',alignItems: 'center',justifyContent: 'center'}}>
                    <ReSend recipient={localStorage.getItem("Cell")}/>
                    <button style={{margin: 0}} className="btn" onClick={update}><span className="Title" style={{fontSize: '30px'}}>Invia</span></button>
                </div>
                </>
            )}
            {Status.Status && Status.Error === "" && 
                <>
                    {navigate("/landing",{replace: true})}
                </>
            }
            {Status.Status && Status.Error !== "" && 
                <>
                    <h2>Token Errato</h2>
                    <p>{Status.Error}</p>
                    <div style={{width: '50%',margin: '20px', display:'flex',alignItems: 'center',justifyContent: 'center'}}>
                        <button className="btn" onClick={()=>OnWrong()}><span className="Title" style={{fontSize: '30px'}}>Indietro</span></button>
                    </div>
                </>
            }
        </div>
    )
}
export default SmsVerify;